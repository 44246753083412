@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Global Styles */
body {
  @apply bg-gray-900 text-white;
  font-family: 'Press Start 2P', sans-serif;
  /* Pixel art font */
}

h1,
h2,
h3 {
  @apply text-yellow-400;
}